.right_icon {
    position: fixed;
    right: -35px;
    top: 15%;
    transform: translate(-50%);
    display: flex;
    flex-direction: column;
    gap: 37px;
    z-index: 3;
    @media only screen and (max-width: 800px) {
        display: none;
    }
}

.right_icon_mobile {
    position: fixed;
    right: -35px;
    top: 22%;
    transform: translate(-50%);
    display: flex;
    flex-direction: column;
    gap: 30px;
    z-index: 3;
    @media only screen and (min-width: 801px) {
        display: none;
    }
    
}

